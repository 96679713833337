import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Menu as MenuIcon, Settings as SettingsIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { accountSelector } from 'src/selectors/selectors';
import Account from './Account';
import CustomClock from './Clock';
import LanguagePopover from './LanguagePopover';
import ThemeModeToggle from './ThemeModeToggle';

const useStyles = makeStyles(theme => ({
  root: {
    ...(theme.name === THEMES.LIGHT.name
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK.name
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 50
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(accountSelector);
  const isloggedIn = useMemo(() => !!user, [user]);
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      // className={clsx(classes.root, className)}
      // {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {isloggedIn ? (
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
              size="large"
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
        ) : (
          <>
            <IconButton
              sx={{ visibility: 'hidden' }}
              color="inherit"
              size="large"
            >
              <SvgIcon fontSize="small">
                <SettingsIcon />
              </SvgIcon>
            </IconButton>
            <Box ml={2} flexGrow={1} />
            <RouterLink to="/">
              <Logo campaignLogoOverride />
            </RouterLink>
          </>
        )}

        <Hidden lgDown>
          {isloggedIn && (
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          )}
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {isloggedIn && (
          <Hidden lgDown>
            <CustomClock />
          </Hidden>
        )}
        {/* <Search /> todo need to be  implemented */}
        {/* <Contacts /> todo need to be  implemented */}
        {/* <Notifications /> todo need to be  implemented  */}
        {isloggedIn && <ThemeModeToggle />}
        {/* <Settings /> */}
        {isloggedIn && (
          <IconButton
            color="inherit"
            onClick={() => history.push('/app/affiliate/settings')}
            size="large"
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        )}
        <LanguagePopover />
        {isloggedIn && (
          <Box ml={2}>
            <Account />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

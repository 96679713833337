import { Container, Divider, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import MiniDrawer from 'src/layouts/DashboardLayout/SubBar';
import { brandingSelector } from 'src/selectors/selectors';
import track from 'src/utils/analytics';
import Box from '@mui/material/Box';
import Logo from './Logo';

/**
 * A page component that wraps its content with a MiniDrawer layout, typically used in a dashboard layout.
 * It includes a title, main content area, and a footer with a link to Tracknow.
 *
 * @component
 * @example
 * import { Box, Typography } from '@mui/material';
 * import Page from 'src/components/Page';
 *
 * const MyPage = () => {
 *   return (
 *     <Page title="My Page">
 *       <Typography variant="h1">Welcome to My Page</Typography>
 *       <Typography variant="body1">
 *         This is the main content of the page.
 *       </Typography>
 *     </Page>
 *   );
 * };
 *
 * export default MyPage;
 *
 * @param {object} props - The component props.
 * @param {string} props.title - The title of the page, displayed in the browser tab.
 * @param {React.ReactNode} props.children - The main content to be displayed on the page.
 * @param {('xs'|'sm'|'md'|'lg'|'xl'|false)} [props.maxWidth] - The maximum width of the container. Accepts one of the following values:
 * - `'xs'`: Extra small devices (screen width < 600px).
 * - `'sm'`: Small devices (screen width ≥ 600px).
 * - `'md'`: Medium devices (screen width ≥ 960px).
 * - `'lg'`: Large devices (screen width ≥ 1280px).
 * - `'xl'`: Extra large devices (screen width ≥ 1920px).
 * - `false`: No maximum width.
 * @param {object} [props.containerSx] - Additional styles to be applied to the Container component.
 * @param {object[]} [props.subBarItems] - An array of items to be displayed in the MiniDrawer's sub bar. Each item should have `label`, `icon`, and `onClick` properties.
 * @param {string} props.subBarItems[].label - The label text of the sub bar item.
 * @param {string} props.subBarItems[].icon - The icon of the sub bar item, passed to the `Iconify` component.
 * @param {function} props.subBarItems[].onClick - The function to call when the sub bar item is clicked.
 * @param {boolean} props.subBarItems[].subheader =false - if the item is sub header.
 * @param {boolean} props.subBarItems[].scope - The scope of the item item, passed to the `ScopeGuard` component.
 * @param {boolean} props.searchableBar - Determent if search bar is showing in sidebar defualt false.
 * @param {boolean} props.activeValue - Determent active tab showing in sidebar defualt 0.

 * @returns {React.Element} The rendered component.
 */

const Page = ({
  title,
  children,
  maxWidth = false,
  containerSx = {},
  activeValue,
  subBarItems,
  searchableBar,
  canonicalLink = null,
  ...rest
}) => {
  const location = useLocation();
  const branding = useSelector(brandingSelector);
  const domainAlias = useSelector(state => state?.config?.domain?.domainAlias);
  const hideFooter = useSelector(state => state?.company?.hideFooter);
  const customFooter = useSelector(state => state?.company?.customFooter);

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div {...rest}>
      <MiniDrawer
        activeValue={activeValue}
        searchable={searchableBar}
        subBarItems={subBarItems}
      >
        <Helmet>
          <title>{branding?.seoTitle || domainAlias}</title>
          {canonicalLink && <link rel="canonical" href={canonicalLink} />}
        </Helmet>
        <Container sx={containerSx} maxWidth={maxWidth}>
          {children}
        </Container>
        {!hideFooter && <Divider sx={{ my: 1 }} />}
        {customFooter ? (
          <Typography
            color="textPrimary"
            variant="subtitle2"
            align="center"
            visibility={hideFooter ? 'hidden' : 'inherit'}
          >
            {customFooter}
          </Typography>
        ) : (
          <Typography
            color="textPrimary"
            variant="subtitle2"
            align="center"
            visibility={hideFooter ? 'hidden' : 'inherit'}
          >
            <Link
              color="textPrimary"
              href="https://www.tracknow.io/?utm_source=poweredby&utm_content=footer"
              target="_blank"
              sx={{ textDecoration: 'none', fontWeight: 400 }}
            >
              Affiliate Software
            </Link>
            <br />
            <Box alt="Affiliate Software" sx={{ marginTop: 1 }}>
              <Link
                sx={{ width: 140, cursor: 'pointer' }}
                href="https://www.tracknow.io/?utm_source=poweredby&utm_content=footer"
                target="_blank"
              >
                <Logo isNegativeMode onlyTracknow width={140} />
              </Link>
            </Box>
          </Typography>
        )}
      </MiniDrawer>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  maxWidth: PropTypes.any,
  containerSx: PropTypes.any,
  canonicalLink: PropTypes.string,
  subBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  )
};

export default Page;

import ajaxUtils from '../utils/ajaxUtils';
import { get, post } from '../utils/axios';
import { rebuildCampaignData } from '../utils/utils';

export const getCampaigns = async () => {
  const response = await get(ajaxUtils.userActions.affiliate.campaigns);
  return response?.data;
};

export const searchCampaignsWithFilters = async data => {
  const trafficSourceIds = data?.trafficSources.map(item => item.oid);
  const countries = data?.countries.map(item => item.code);
  const params = new URLSearchParams({
    countries,
    trafficSourceIds
  });
  const response = await get(
    ajaxUtils.userActions.affiliate.campaignsSearch,
    params
  );
  return response?.data;
};

export const getCampaignsAsMiniList = async () => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaigns}/options`
  );
  return response?.data;
};

export const getCampaign = async oid => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaigns}/settings/${oid}`
  );
  return response?.data;
};
export const getCampaignTrackingData = async oid => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaigns}/${oid}/tracking-data`
  );
  return response?.data;
};

export const getCampaignLogoFromSerialOid = async campaignSerialOid => {
  const response = await get(`${ajaxUtils.campaignLogoFilePath}`, {
    campaignSerialOid: campaignSerialOid
  });
  return response?.data;
};

export const getCampaignsWithCreativeLinksTrackingData = async () => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaignsCreativeLinks}`
  );
  return response?.data;
};

export const createCampaign = async data => {
  const response = await post(
    `${ajaxUtils.userActions.affiliate.campaigns}/settings/`,
    rebuildCampaignData(data, data.file),
    null,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response?.data;
};

// eslint-disable-next-line import/prefer-default-export
export const requestToJoinCampaign = async (campaignSerialOid, requestText) => {
  await post(
    `${ajaxUtils.userActions.affiliate.campaigns}/${campaignSerialOid}/request-to-join`,
    null,
    { requestText }
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getRequestToJoinData = async campaignSerialOid => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaigns}/${campaignSerialOid}/requests`
  );
  return response?.data;
};

export const getCampaignCreatives = async (
  campaignSerialOid,
  startDate,
  endDate,
  title,
  creativeType,
  sort,
  sortField,
  pageNumber,
  pageSize
) => {
  startDate = startDate !== null ? startDate.toISOString() : null;
  endDate = endDate !== null ? endDate.toISOString() : null;
  const response = await get(
    `${ajaxUtils.userActions.affiliate.campaigns}/creatives`,
    {
      campaignSerialOid,
      startDate,
      endDate,
      title,
      creativeType,
      sort,
      sortField,
      pageNumber,
      pageSize
    }
  );
  return response?.data;
};

export const getCampaignFilteredDailyStats = async filters => {
  const startDate = filters.startDate?.toISOString();
  const endDate = filters.endDate?.toISOString();
  const { groupBy, groupBy2 } = filters;
  const response = await get(
    ajaxUtils.companyActions.statistics.dailyStats.filtered,
    {
      startDate,
      endDate,
      groupBy,
      groupBy2,
      paymentTypes: filters?.paymentTypes?.map(f => f?.name).toString()
    }
  );
  return response?.data;
};
